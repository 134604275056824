<template>
 <v-container id="dashboard-steps-page" class="grey lighten-5 pa-0 ma-0">
    <v-form  @submit.prevent="formSubmit">
      <base-card elevation="5" class="pa-0 ma-0" color="#f2f2f2" :loading="donorSearchLoading">
        <v-row no-gutters class="pa-0 ma-0" style="min-height: 550px;">
          <v-col cols="4" class="pa-0 ma-0 pa-7">
              <step-sidebar @goHome="goBack('home')" @next="autoNext" :currentPage="currentPage" />
          </v-col>
          <v-col cols="8" class="pa-0 ma-0 pa-7 white">
              <v-card-title class="primary--text font-weight-bold d-inline-block pa-0 title-border">
                <span>Step {{ currentPage }}: </span>{{stepTitle}}
              </v-card-title>
              <step-one v-if="currentPage === 1" :valid="$v"/>
              <step-two v-if="currentPage === 2" :v="$v"/>
              <step-three v-if="currentPage === 3" @next="formSubmit" :v="$v" @selectSF="existingLpPayID=$event;"/>
              <step-four :stripeEl="stripe" v-if="currentPage === 4" :v="$v" />
              <step-four :stripeEl="stripe" v-if="currentPage === 5" :v="$v" />
          </v-col>
        </v-row>
        <step-footer :v="$v" :currentPage="currentPage" @goHome="goBack('home', $event)" @back="goBack" @optionsOpen="optionsOpen = true" @followUP="modalOpen=true"/>
      </base-card>
      <FollowUpModal :modalOpen="modalOpen" @close="modalOpen=false" @save="followUpSave($event)"/>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { email, required } from "vuelidate/lib/validators";

export default {
    name: "LPEntry",
    metaInfo: {
        title: "LP Entry",
    },
    components: {
        StepSidebar: () => import("@/pages/lp-entry/StepSidebar"),
        StepFooter: () => import("@/pages/lp-entry/StepFooter"),
        StepOne: () => import("@/pages/lp-entry/components/step-forms/Step1"),
        StepTwo: () => import("@/pages/lp-entry/components/step-forms/Step2"),
        StepThree: () => import("@/pages/lp-entry/components/step-forms/Step3"),
        StepFour: () => import("@/pages/lp-entry/components/step-forms/Step4"),
        Modal: () => import("@/components/common/modal"),
        FollowUpModal: () => import("@/pages/lp-entry/components/FollowUpModal"),
    },
    data: (vm) => ({
        currentPage: 1,
        stepTitle: 'Personal Information',
        modalOpen: false,
        stripe: null,
        existingLpPayID: null
    }),
    computed: {
        ...mapGetters(["donorSearchLists", "donorSearchLoading", "apiReqData", "followUpModel", "producerActCampModel"]),
    
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("setModel", newValue);
            },
        },
    },
    watch: {
        currentPage(newVal) {
        this.$store.commit('setCurrentStep', newVal);
        }
    },
    validations() {
        const valid = {
        model: {
            email: { email},
            first_name: { required},
            last_name: { required},
        }
        };
        if (this.currentPage === 2 && !this.model.email && !this.model.suburb && !this.model.address1) {
            valid.model['email'] = {...valid.model['email'], ...{ required }};
        } else if (this.currentPage === 4) {
        if (['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) !== -1) {
            valid.model['start_date'] = { required };
            if (this.model.paymentMethod === 'CQ') {
            valid.model['bsb'] = { required };
            valid.model['account_no'] = { required };
            valid.model['cheque_no'] = { required };
            valid.model['drawer'] = { required };
            }
        }
        valid.model['amount'] = { required };
        }
        return valid;
    },
    created() {
        this.getLatestIncentive();
        this.$store.dispatch('refreshData');
        if (this.$helpers.getCurData("curDonate")) {
            this.$router.push('/');
        }
        this.getActiveCampaign().then(resp => this.model.sf_campaign_id = resp.data.data.sf_campaign_once);
    },
    mounted() {
        this.stripe = window.Stripe(`${window.VUE_APP_STRIPE_PUBLISHABLE_KEY}`);
    },
    methods: {
        ...mapActions(["donatePaymentAmount", "updateFollowUp", "getActiveCampaign",
            "getLatestIncentive", 'searchAccount', "createPaymentIndent"]),

        goBack (type=null, reset) {
            if (this.currentPage === 4 && !this.model.id) {
                this.currentPage = 2;
            } else if (this.currentPage !== 1 && !type) {
                this.currentPage -= 1 ;
            } else {
                this.currentPage = 1;
            }
            if (['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) === -1) this.model.cardValidate =  false;
            if(reset) this.model.sf_campaign_id = this.producerActCampModel.sf_campaign_once;
            this.titleChange();
        },
        titleChange () {
            switch (this.currentPage) {
                case 1:
                    this.stepTitle = 'Search for a match';
                    return '';
                case 2:
                    this.stepTitle = 'Update Details';
                    return '';
                case 3:
                    this.stepTitle = 'Light Partnership';
                    return '';
                case 4:
                    this.stepTitle = 'Process Donation';
                    return '';
                case 5:
                    this.stepTitle = 'Donation Selection';
                    return '';
                default:
                    this.stepTitle = 'Search for a match';
                    break;
            }
        },
        autoNext () {
            if (this.currentPage === 1) {
                if (this.model.id) this.getBusinessLists({ contact_id: this.model.id});
                this.currentPage += 1;
                this.titleChange();
            }
        },
        formSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if (this.currentPage === 4 &&['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) === -1) {
                this.model.stripeErr = "";
                const payload = {
                    amount: this.model.amount,
                    stripe_id: this.model.stripe_customer_id,
                    name: this.model.name || `${this.model.first_name} ${this.model.last_name}`,
                    email: this.model.email,
                    description: `${this.model.name || `${this.model.first_name} ${this.model.last_name}`} Single Charge`,
                    contact_id: this.model.id,
                    first_name: this.model.first_name,
                    last_name: this.model.last_name,
                    phone: this.model.phone,
                    address1: this.model.address1,
                    suburb: this.model.suburb,
                    state: this.model.state,
                    postcode:  this.model.postcode,
                }
                this.getPaymentIntent(payload);
            } else if (this.currentPage === 5 || (this.currentPage === 4 && ['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) !== -1)) {
                this.onSubmit();
            } else if (this.currentPage === 2 && !this.model.id) {
                this.currentPage = 4;
            } else {
                if (this.currentPage < 2 && this.model.id && !this.model.business) this.getBusinessLists({ contact_id: this.model.id });
                else this.currentPage += 1;
            }
            this.titleChange();
        },
        getPaymentIntent(payload) {
            this.$store.commit("setStripLoading", true);
            this.createPaymentIndent(payload).then(data => {
                this.$store.commit("setStripLoading", false);
                this.model.client_secret = data.client_secret;
                this.model.setup_intent = data.setup_intent || false;
                if(data.customer_id) this.model.stripe_customer_id = data.customer_id;
                if(data.contact_id) this.model.id = data.contact_id;
                this.$store.commit("updateModel", this.model);
                this.currentPage += 1;
            }).catch(() => this.$store.commit("setStripLoading", false));
        },
        confirmPaymentIntent(key) {
            this.$store.commit("setAppLoading", true);
            const fieldName = this.$filters.titleCase(key.split('_').join(' '));
            const payIntent = {
                return_url: `${window.VUE_APP_LIVE_URL}/3d-secure-completed`, //card
                setup_future_usage: 'off_session'
            }
            const confirmPaymentOptions = {
            payment_method:  this.model.existing_pay_id ? this.model.existing_pay_id : {
                [key]: key === 'card' ? this.model.cardNumber : this.model.auBankAccount,
                billing_details: { //sudebit
                name: this.model.name || `${this.model.first_name} ${this.model.last_name}`,
                email: this.model.email,
                },
            },
            ...!this.model.setup_intent ? payIntent : {}
            };

            this.stripe[`confirm${fieldName.replace(/ +/g, '')}${this.model.setup_intent ? 'Setup' : 'Payment'}`](this.model.client_secret, confirmPaymentOptions)
            .then((result) => {
                this.$store.commit("setAppLoading", false);
                if(result.error) {
                    this.$store.commit("snackbar/SHOW_MESSAGE", {
                        text: result.error.message || 'Payment Failed',
                        color: "error",
                    });
                } else if(result.paymentIntent && result.paymentIntent.next_action) {
                    this.$store.commit("setStripLoading", true);
                    const self = this;
                    window.addEventListener('message', function(ev) {
                        self.$store.commit("setStripLoading", false);
                        if (ev.data === '3DS-authentication-complete') {
                            self.on3DSComplete();
                        }
                    }, false);
                } else {
                    this.model.payment_intent_id = result[this.model.setup_intent ? 'setupIntent' : 'paymentIntent'].id;
                    this.updateStripeResponse(result);
                }
            }).catch(err => this.$store.commit("setAppLoading", false));
        },
        getBusinessLists(reqData) {
            this.searchAccount(reqData).then((response) => {
                if (response.length) {
                    this.model.businessLists = response;
                    const defaultVal = this.model.sf_account_id ? response.find(item => item.sf_id === this.model.sf_account_id) : null;
                    this.model.business = defaultVal ? defaultVal : response[0];
                }
            }).catch();
        },
        onSubmit() {
            if (['CQ', 'C', 'DT'].indexOf(this.model.paymentMethod) !== -1) {
                this.$store.commit("updatePayReqModel", this.model);
                this.$store.commit("toggleAppLoading");
                this.donatePayment();
            } else {
                this.confirmPaymentIntent(this.model.paymentMethod == 'CC' ? 'card' : 'au_becs_debit');
            }
        },
        updateStripeResponse(result) {
            if (result.error) {
                this.model.stripeErr = result.error.message;
                this.$store.commit("toggleAppLoading");
                this.$store.commit("updateModel", this.model);
            } else {
                this.model.stripeErr = '';
                this.$store.commit("updateModel", this.model);
                this.donatePayment();
            }
        },
        donatePayment() {
            if(this.existingLpPayID) this.apiReqData.existingLP_ID = this.existingLpPayID;
            if (this.model.businessDonation) {
                this.apiReqData.sf_account_id = this.model.sf_account_id;
            }
            const reqData = this.apiReqData;
            delete reqData.sf_id;
            this.$store.commit("setAppLoading", true);
            this.donatePaymentAmount(reqData).then((response) => {
                if (!response || !response.data) {
                    return;
                }
                if (response.data.data.status !== 0) {
                    this.$helpers.lsPush("curDonate", response.data.data.id);
                    if(this.followUpModel.type) {
                        const reqData = this.followUpModel;
                        reqData.donation_id =  response.data.data.id;
                        this.followUpSave(reqData);
                    }
                    this.$router.push('/');
                }
            }).catch(() => {});
        },
        followUpSave(reqData) {
            this.updateFollowUp(reqData).then(() => {
                this.modalOpen = false;
            });
        },
    }
    };
</script>

<style lang="scss">
    .selectable {
        .v-label {
            color: white !important;
        }
    }
</style>
